import { NgComponentOutlet } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { LazyInitializatorInjector } from '@bcf-libs/app-utils/src/lib/utils';
import { AppLoadedService } from '@bcf-v2-ui-shared/services/app-loaded.service';
import type { AppMaintenanceService } from '@bcf-v2-ui-shared/services/app-maintenance.service';
import { maintenanceRoutePath } from '@bcf-v2/routes/common/app/paths/maintenance-route-path';
@Component({
  standalone: true,
  selector: 'bcf-root',
  imports: [RouterOutlet, NgComponentOutlet],
  templateUrl: './app.component.html',
  providers: [LazyInitializatorInjector]
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _injector: Injector,
    private _appLoadedService: AppLoadedService
  ) {}

  public ngOnInit(): void {
    this._initApp();
    this._initAppMaintenance();
    this._appLoadedService.init();
  }

  private async _initApp(): Promise<void> {
    this._injector
      .get(
        (await import(/* webpackChunkName: "app-initializator" */ './../../../mobile-web/src/app/app-initializator'))
          .LazyInitializatorSbLetsbetmdMobileWeb
      )
      .init();
  }

  private async _initAppMaintenance(): Promise<void> {
    const instance: AppMaintenanceService = this._injector.get(
      (await import(/* webpackChunkName: "app-maintenance" */ '@bcf-v2-ui-shared/services/app-maintenance.service'))
        .AppMaintenanceService
    );
    instance.doRedirectToMaintenance().subscribe(() => this._router.navigate([maintenanceRoutePath()]));
  }
}
