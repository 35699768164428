import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter, withDisabledInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideBcfAppId, provideBcfReleaseVersion } from '@bcf-v2-configurators/tokens/app/provider';
import { buildSettings } from '@env/build-settings';
import { releaseVersion } from '@env/release-version';
import { bcfAppId } from '../bcf-app-id';
import { envAppProvidersBrowser } from '../env-app-providers.browser';
import { appBrowserProviders } from './app.providers';
import { routes } from './app.routes';

const appRuntimeId: number = new Date().getTime();
const worker: Worker = new Worker(new URL(/* webpackChunkName: "app-worker" */ '../worker', import.meta.url));

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes, withDisabledInitialNavigation(), withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !buildSettings.isDevServer && buildSettings.environment === 'prod'
    }),
    provideBcfAppId(bcfAppId),
    provideBcfReleaseVersion(releaseVersion),
    ...envAppProvidersBrowser(worker, appRuntimeId),
    ...appBrowserProviders
  ]
};
