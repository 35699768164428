import { Inject, Injectable } from '@angular/core';
import { ENV_BASE, EnvBase } from '@bcf-v2-configs/env-base';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { addDays } from 'date-fns';
import { Observable, Subject, first, lastValueFrom, map, of, race, startWith, tap, timer } from 'rxjs';
import { CookiesContract } from './cookies-contract';
import { CookiesForProviders } from './cookies-for-providers';
import { mapCookies, setCookie } from './utils';

@Injectable({ providedIn: 'root' })
export class Cookies implements CookiesContract {
  private _cookies: Record<string, string> = {};
  private _didCookiesUpdated$: Subject<void> = new Subject<void>();
  private _cookiesSetChannel?: WorkerSynchronizer<string>;

  constructor(
    @Inject(ENV_BASE) private _envBase: EnvBase,
    private _workerSynchronizerSpawner: WorkerSynchronizerSpawner,
    private _cookiesForProviders: CookiesForProviders
  ) {
    this._init();
  }

  private _init(): void {
    this._cookiesSetChannel = this._workerSynchronizerSpawner.spawn('cookiesSetChannel');

    const cookiesChannel: WorkerSynchronizer<Record<string, string>> =
      this._workerSynchronizerSpawner.spawn('cookiesChannel');
    cookiesChannel.message$
      .pipe(map((cookies: Record<string, string>) => mapCookies(cookies, this._envBase.cookiesMap ?? {})))
      .subscribe((cookies: Record<string, string>) => {
        this._cookies = cookies;
        this._didCookiesUpdated$.next(undefined);
        this._cookiesForProviders.setCookies(cookies);
      });
  }

  public set(name: string, value: string, expires?: Date, path?: string, domain?: string): void {
    this._cookiesSetChannel?.next(setCookie(name, value, expires, path, domain));
  }

  public get keys(): string[] {
    return Object.keys(this._cookies);
  }

  public getEager(key: string): string | undefined {
    return this._cookies[key];
  }

  public getEagerAll(): Record<string, string> {
    return this._cookies;
  }

  public getCsrfToken(): Promise<string> {
    return lastValueFrom(
      race(
        timer(500).pipe(
          map(() => this._cookies['csrftoken'] ?? ''),
          tap(() => console.warn('csrf after timeout 500'))
        ),
        this._didCookiesUpdated$.pipe(
          startWith(undefined),
          map(() => this._cookies['csrftoken'] ?? ''),
          first((token: string) => token.length > 0)
        )
      )
    );
  }

  public didCookiesUpdated(): Observable<void> {
    return this._didCookiesUpdated$.asObservable();
  }

  public getAffiliateId(): Observable<string | undefined> {
    return of(this._cookies['affiliateId']);
  }

  public getDefaultLang(): Observable<string | undefined> {
    return of(this._cookies['bcfDefaultLang']);
  }

  public getThemeState(): Observable<string | undefined> {
    return of(this._cookies['prefers_color_scheme']);
  }

  public setLang(lang: string): void {
    // key must be same as in SSR, check fn getCookieLang()
    this.set('bcfDefaultLang', lang, addDays(new Date(), 31));
  }
}
